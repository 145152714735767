import { PM } from '@kulee/helper'
import { GAME_EVENTS, RESPONSE_CODES_FOR_GAME } from '@kulee/tga-constant'

import BaseModule from '../../BaseModule'
import logger from '../../config/logger'
import type BaseProvider from '../../BaseProvider'
import type { SimpleGameType } from '../../types/game'
import type { GameInteractionOptions, InitGameData } from '../../types/gameInteraction'

const USER_MOCK = {
  userId: '123',
  nickname: 'Visitor',
  avatar: ''
}
class GameInteraction extends BaseModule {
  public static override clazz = 'GameInteraction'

  private provider!: BaseProvider
  private pm!: PM
  private game!: SimpleGameType
  private showAdFnCache: (() => void) | null = null

  private gameLoadCompleteHandled = false

  public override run(): void {
    logger.log(`run module ${GameInteraction.clazz}`)
  }

  public init(options: GameInteractionOptions) {
    const { iframeEle, provider } = options

    this.provider = provider
    this.game = provider.getGameInfo()

    this.pm = PM.getInstance({
      target: iframeEle.contentWindow as Window
    })
    this.pm.removeAll()

    this.gameLoadCompleteHandled = false

    this.addEventListener()
  }

  /**
   * 初始化游戏数据
   */
  public async initGame(data: InitGameData) {
    // TODO: 处理游戏存档
    const user = data.user ? data.user : USER_MOCK
    const gameRoom = data.gameRoom ? data.gameRoom : undefined

    this.pm.emit(GAME_EVENTS.ON_GAME_INIT, {
      game: this.game,
      user,
      gameRecord: {},
      gameRoom
    })
  }

  /**
   * 开始游戏
   */
  public startGame() {
    this.pm.emit(GAME_EVENTS.ON_GAME_START)
  }

  /**
   * 暂停游戏
   */
  public pauseGame() {
    this.pm.emit(GAME_EVENTS.ON_GAME_PAUSE)
  }

  /**
   * 继续游戏
   */
  public resumeGame() {
    this.pm.emit(GAME_EVENTS.ON_GAME_RESUME)
  }

  /**
   * 发送对战游戏数据
   * @param data 游戏数据
   */
  public sendBattleGameData(data: string) {
    this.pm.emit(GAME_EVENTS.ON_RECEIVE_GAME_MESSAGE, data)
  }

  private addEventListener() {
    const { SDK, pm, provider } = this

    // ----游戏前----

    // 上报游戏加载进度
    pm.on(GAME_EVENTS.REPORT_LOADING_PROGRESS, ({ data }) => {
      provider.loadingProgress(data)
    })

    // 游戏资源加载完成
    pm.on(GAME_EVENTS.GAME_LOAD_COMPLETE, () => {
      logger.log('[test] receive GAME_EVENTS.GAME_LOAD_COMPLETE', this.gameLoadCompleteHandled)
      if (!this.gameLoadCompleteHandled) {
        this.gameLoadCompleteHandled = true
        logger.log('[test] receive GAME_EVENTS.GAME_LOAD_COMPLETE call', this.gameLoadCompleteHandled)
        provider.gameLoadComplete()
      }
    })

    // 游戏已准备就绪
    pm.on(GAME_EVENTS.GAME_READY, () => {
      provider.gameReady()
    })

    // ----游戏中----

    // 上报用户通过的关卡
    pm.on(GAME_EVENTS.REPORT_LEVEL_PASSED, async ({ data }) => {
      const { level } = data
      // TODO 后续功能调服务端上报用户完成的游戏关卡
      logger.log('user level passed saved.', level)
    })

    // 上报用户通关
    pm.on(GAME_EVENTS.REPORT_CLEAR_GAME, () => {
      // TODO 参考REPORT_LEVEL_PASSED_EVENT_TYPE
      logger.log('the state of all of the level user passed saved.')
    })

    // 上报积分
    pm.on(GAME_EVENTS.REPORT_SCORE, async ({ data }) => {
      const {
        scoreInfo: { rankId, score }
      } = data
      try {
        await SDK.module.Rank.service.reportGameRankScore({
          rankId,
          point: score
        })
        pm.emit(GAME_EVENTS.REPORT_SCORE_RESULT, {
          code: RESPONSE_CODES_FOR_GAME.SUCCESS,
          message: 'success'
        })
      } catch (err: any) {
        pm.emit(GAME_EVENTS.REPORT_SCORE_RESULT, {
          code: RESPONSE_CODES_FOR_GAME.UNKNOWN_ERROR,
          message: 'Fail'
        })
      }
    })

    // 显示排行榜
    pm.on(GAME_EVENTS.SHOW_RANK_MODAL, () => {
      provider.showRank()
    })

    // 打开分享面板
    pm.on(GAME_EVENTS.SHARE, () => {
      provider.showShare()
    })

    // 打开全局消息
    pm.on(GAME_EVENTS.OPEN_MESSAGE, ({ data }) => {
      const { type, content } = data
      provider.openMessage({
        type,
        content,
        onClosed: () => {
          pm.emit(GAME_EVENTS.ON_MESSAGE_CLOSED)
        }
      })
    })

    // 获取广告配置
    pm.on(GAME_EVENTS.GET_AD_CONFIG, () => {
      logger.log(`receive postmessage event '${GAME_EVENTS.GET_AD_CONFIG}'`)
      pm.emit(GAME_EVENTS.ON_AD_CONFIG_RECEIVED, {
        code: RESPONSE_CODES_FOR_GAME.SUCCESS,
        message: 'success',
        payload: SDK.module.Ad.getGameAdConfig()
      })
    })

    pm.on(GAME_EVENTS.AD_INIT, async () => {
      logger.log(`receive postmessage event '${GAME_EVENTS.AD_INIT}'`)

      let initRes = false
      try {
        initRes = await this.SDK.module.Ad.init()
      } catch (error) {}

      pm.emit(GAME_EVENTS.ON_AD_INITED, initRes)
    })

    pm.on(GAME_EVENTS.SHOW_PREROLL_AD, () => {
      logger.log(`receive postmessage event '${GAME_EVENTS.SHOW_PREROLL_AD}'`)

      this.SDK.module.Ad.showPrerollAd({
        name: this.game.name,
        onError: (payload: any) => {
          logger.log(`show preroll ad onError`)
          pm.emit(GAME_EVENTS.ON_AD_EVENT, {
            type: 'preroll',
            action: 'onError',
            payload
          })
        },
        onFinish: () => {
          logger.log(`show preroll ad onFinish`)
          pm.emit(GAME_EVENTS.ON_AD_EVENT, {
            type: 'preroll',
            action: 'onFinish'
          })
        }
      })
    })

    pm.on(GAME_EVENTS.SHOW_INTERSTITIAL_AD, ({ data: type }) => {
      logger.log(`receive postmessage event '${GAME_EVENTS.SHOW_INTERSTITIAL_AD}'`)

      this.SDK.module.Ad.showInterstitialAd({
        name: this.game.name,
        type,
        onPlay: () => {
          logger.log(`show interstitial ad onPlay`)
          pm.emit(GAME_EVENTS.ON_AD_EVENT, {
            type: 'interstitial',
            action: 'onPlay'
          })
        },
        onError: (payload: any) => {
          logger.log(`show interstitial ad onError`)
          pm.emit(GAME_EVENTS.ON_AD_EVENT, {
            type: 'interstitial',
            action: 'onError',
            payload
          })
        },
        onFinish: () => {
          logger.log(`show interstitial ad onFinish`)
          pm.emit(GAME_EVENTS.ON_AD_EVENT, {
            type: 'interstitial',
            action: 'onFinish'
          })
        }
      })
    })

    pm.on(GAME_EVENTS.PRE_SHOW_REWARD_AD, async () => {
      logger.log(`receive postmessage event '${GAME_EVENTS.PRE_SHOW_REWARD_AD}'`)

      this.SDK.module.Ad.showRewardAd({
        name: this.game.name,
        beforePlay: (showAdFn: (() => void) | null) => {
          logger.log(`show reward ad beforePlay`)
          this.showAdFnCache = showAdFn
          pm.emit(GAME_EVENTS.ON_AD_EVENT, {
            type: 'reward',
            action: 'beforePlay'
          })
        },
        onPlay: () => {
          logger.log(`show reward ad onPlay`)
          pm.emit(GAME_EVENTS.ON_AD_EVENT, {
            type: 'reward',
            action: 'onPlay'
          })
        },
        onReward: () => {
          logger.log(`show reward ad onReward`)

          pm.emit(GAME_EVENTS.ON_AD_EVENT, {
            type: 'reward',
            action: 'onReward'
          })
        },
        onError: (payload: any) => {
          logger.log(`show reward ad onError.`)

          pm.emit(GAME_EVENTS.ON_AD_EVENT, {
            type: 'reward',
            action: 'onError',
            payload
          })
        },
        onFinish: () => {
          logger.log(`show reward ad onFinish`)

          pm.emit(GAME_EVENTS.ON_AD_EVENT, {
            type: 'reward',
            action: 'onFinish'
          })
        }
      })
    })

    pm.on(GAME_EVENTS.SHOW_REWARD_AD, () => {
      logger.log(`receive postmessage event '${GAME_EVENTS.SHOW_REWARD_AD}'`)

      if (this.showAdFnCache) {
        this.showAdFnCache()
        this.showAdFnCache = null
      }
    })

    pm.on(GAME_EVENTS.SEND_AD_EVENT, ({ data }) => {
      logger.log(`receive postmessage event '${GAME_EVENTS.SEND_AD_EVENT}', ad type is`, data)

      const { type, action, payload } = data
      switch (action) {
        case 'beforePlay':
          break

        case 'onplay':
          provider.hideOptions()
          break

        case 'onReward':
          break

        case 'onFinish':
          provider.showOptions()
          break

        case 'onError':
          if (type === 'rewardAd') {
            this.handleAdError(payload)
          }
          break

        default:
          break
      }
    })

    // 对战广播游戏消息
    pm.on(GAME_EVENTS.SEND_GAME_MESSAGE, ({ data }) => {
      const { message, exceptSelf } = data

      this.SDK.module.PKGame.sendGameData(message, exceptSelf)
    })

    // 对战游戏结束
    pm.on(GAME_EVENTS.GAME_FINISH, ({ data }) => {
      this.SDK.module.PKGame.gameFinish(data)
    })
  }

  /**
   * 处理广告过程中的错误
   * @param error 错误内容
   */
  private handleAdError(error: { code: string; message?: string; payload?: any }): void {
    const { provider } = this.context

    logger.warn('some ad error, start to show ad error toast', error)

    provider.openMessage({
      type: 'error',
      content: error.code,
      onClosed: () => {}
    })
  }
}

export default GameInteraction
