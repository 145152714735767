import React from 'react'

import useWebTitle from '@/hook/useWebTitle'
import { KULEWEBVIEW } from '@/config/constant'
import LinkEnhance from '@/component/LinkEnhance'
import { ReactComponent as LogoSVG } from '@/asset/svg/logo.svg'

const Logo: React.FC<PropType> = () => {
  const title = useWebTitle()

  return (
    <h1 className="!text-xl">
      {KULEWEBVIEW ? (
        <LinkEnhance>{title}</LinkEnhance>
      ) : (
        <LinkEnhance>
          <LogoSVG className="w-40 fill-current" />
        </LinkEnhance>
      )}
    </h1>
  )
}

interface PropType {}

export default Logo
